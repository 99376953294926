import React from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ProductData } from '../../types'
import { getHash } from '../../utils/Crypto'
import { update } from 'cypress/types/lodash'

export interface FluidProductImageProps {
  productData: ProductData
  imageUrl: string
  className?: string
  productUrl?: string
}

class FluidProductImage extends React.Component {
  constructor(options: FluidProductImageProps) {
    super(options)
    this.state = FluidProductImage.updateContent(options)
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.imageUrl != prevState.options.imageUrl) {
      return FluidProductImage.updateContent(nextProps)
    } else {
      return null
    }
  }

  handleClick = () => {
    if (typeof this.state.options.productUrl !== 'undefined') {
      navigate(this.state.options.productUrl)
    }
  }

  static updateContent = (options: FluidProductImageProps) => {
    if (options.productData.base64 === null) {
      options.productData.base64 =
        '/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/wAALCAAGAAYBAREA/8QAFAABAAAAAAAAAAAAAAAAAAAACf/EAB0QAAICAQUAAAAAAAAAAAAAAAECAxEEAAUTITH/2gAIAQEAAD8AT7CidN6zJOeRkdRSMxIU9XQ8Gv/Z'
    }

    const image: IGatsbyImageData = {
      layout: 'fullWidth',
      width: 500,
      height: 500,
      backgroundColor: 'white',
      images: {
        fallback: {
          sizes: '(max-width: 500px) 100vw, 500px',
          src: options.imageUrl,
          srcSet: options.imageUrl + ' 500w',
        },
        sources: [
          {
            sizes: '(max-width: 500px) 100vw, 500px',
            srcSet: options.imageUrl + ' 500w',
            type: 'image/jpeg',
          },
        ],
      },
      placeholder: {
        fallback: 'data:image/jpg;base64,' + options.productData.base64,
      },
    }

    let className = ''

    if (typeof options.productUrl !== 'undefined') {
      className = 'clickableLink'
    }

    return { options: options, image: image, className: className }
  }

  // shouldComponentUpdate(nextProps: any) {
  //   let result = false

  //   if (nextProps.imageUrl != this.state.options.imageUrl) {
  //     result = true
  //     console.log('nextState', nextState)
  //   }

  //   return result
  // }

  render() {
    return (
      <div className={this.state.options.className} onClick={this.handleClick}>
        <GatsbyImage
          alt={this.state.options.productData.name}
          className={this.state.className}
          image={this.state.image}
          key={'gatsbyimage-' + getHash(this.state.options)}
          title={this.state.options.productData.name}
        />
      </div>
    )
  }
}

export default FluidProductImage
